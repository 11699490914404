import moment from 'moment'
import "moment/locale/id"
import Utils from './helper/utils';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class Report{
    static invoice(data){
        let start_date = moment(data.start_date, "Y-MM-DD");
        let end_date = moment(data.end_date, "Y-MM-DD");
        let diffInDay = end_date.diff(start_date, "days") + 1;

        let details_transaction = [];
        let detail_no = 1;
        let sub_total = 0;

        data.products.forEach(dt => {
            let subTotalProduct = dt.active_price*diffInDay*dt.serials.length;
            let dtr = [
                {text:(detail_no++).toString(), style:{ bold: true }},
                {text:start_date.format("DD MMM Y"), style:{ bold: true }},
                {text:dt.name, style:{ bold: true }},
                {text:Utils.rupiah(dt.active_price), style:{ bold: true }},
                {text:dt.serials.length.toString(), style:{ bold: true }},
                {text:diffInDay.toString(), style:{ bold: true }},
                {text:Utils.rupiah(subTotalProduct), style:{ bold: true }, alignment: 'right'},
            ];
            sub_total += subTotalProduct;
            details_transaction.push(dtr);
            if(dt.includes.length !== 0){
                dt.includes.forEach(include => {
                    details_transaction.push(['','',{text: include, style:{ italics : true }},'','','','']);
                });
            }
        });

        let sudah_bayar = 0;
        data.detail_payments.forEach(pay => {
            sudah_bayar += pay.payment;
        })

        let optionalRowCount = 0;
        if (data.with_tax) {
            optionalRowCount += 1;
        }
        if (data.with_pph) {
            optionalRowCount += 1;
        }

        let footer_transactions = [
            [{text: '', colSpan: 3, rowSpan: 5 + optionalRowCount}, {}, {}, {text: 'Sub Total', colSpan: 3}, {}, {}, { text : Utils.rupiah(sub_total), style: { bold: true }, alignment: 'right' }],
            [{}, {}, {}, {text: 'Discount', colSpan: 3}, {}, {}, { text : `(${Utils.rupiah(data.discount)})`, style: { bold: true }, alignment: 'right'}],
            [{}, {}, {}, {text: 'Total Setelah Discount', colSpan: 3}, {}, {}, { text : Utils.rupiah(sub_total - data.discount), style: { bold: true }, alignment: 'right'}],
        ]

        if (data.with_tax) {
            footer_transactions.push(
                [{}, {}, {}, {text: 'PPN (+11%)', colSpan: 3}, {}, {}, { text: Utils.rupiah(data.tax_amount || 0), style: { bold: true }, alignment: 'right' }]
            )
        }

        if (data.with_pph) {
            footer_transactions.push(
                [{}, {}, {}, {text: 'PPh (-2%)', colSpan: 3}, {}, {}, { text: `(${Utils.rupiah(data.pph_amount || 0)})`, style: { bold: true }, alignment: 'right' }]
            )
        }

        if (data.with_tax || data.with_ppn) {
            footer_transactions.push(
                [{}, {}, {}, {text: 'Total Setelah Pajak', colSpan: 3}, {}, {}, { text : Utils.rupiah(data.total_price), style: { bold: true }, alignment: 'right'}],
            )
        }

        footer_transactions = [
            ...footer_transactions,
            [{}, {}, {}, {text: 'Sudah Bayar', colSpan: 3}, {}, {}, { text: `(${Utils.rupiah(sudah_bayar)})`, style: { bold: true }, alignment: 'right' }, ],
            [{}, {}, {}, {text: 'Sisa Bayar', colSpan: 3}, {}, {}, { text: Utils.rupiah(data.total_price - sudah_bayar), style: { bold: true }, alignment: 'right' }]
        ]

        let colon = { border : [false, true, false, true], text : ':' }

        let payment_accounts = JSON.parse(data.cabang.invoice_payload).map(x => ({
            style: {
                bold: true,
                lineHeight: 1.2
            },
            text: `
                ${x.bank_name}
                Acc. No. : ${x.account_number}
                Acc. Name : ${x.holder_name}
            `
        }))

        let definition = {
            // watermark: { text: 'LUNAS', color: 'red', opacity: 0.3, bold: true, italics: false },
            footer: {
                columns: [
                    '',
                    {   text: [
                            '____________________________________________________________\n',
                            'www.bsmentertainment.com',
                        ],
                        color: '#6c757d',
                        alignment: 'right',
                        fontSize: 9,
                        marginRight: 40
                    }
                ]
            },
            content: [
                {
                    columns: [
                        {
                            image: data.cabang.logo,
                            width: 100,
                            marginTop: 15
                        },
                        {
                            style: {
                                alignment: 'right'
                            },
                            text : [
                                {
                                    style : {
                                        fontSize : 12,
                                        bold : true,
                                        textAlign: 'right'
                                    },
                                    text : `${data.cabang.formal_name}\n\n`},
                                {
                                    text: 'Head Office: ',
                                    style: {
                                        fontSize: 8,
                                        bold: true
                                    },
                                },
                                {
                                    style : {
                                        fontSize : 8
                                    },
                                    text : 'Jl. Gardu No. 15D, RT. 007 RW. 002 Kel. Balekambang, Kec. Kramat Jati, Jakarta Timur 13530\n'
                                },
                                {
                                    text: 'Store: ',
                                    style: {
                                        fontSize: 8,
                                        bold: true
                                    },
                                },
                                {
                                    style : {
                                        fontSize : 8
                                    },
                                    text : data.cabang.address.replaceAll('\\n', '\n')
                                },
                                {
                                    style : {
                                        fontSize : 8
                                    },
                                    text: '\n\nbsmofficer@gmail.com\nNPWP: 76.005.418.9-005.000'
                                }
                            ]},
                    ]
                },
                {
                    style : "invoice_header",
                    table: {
                        widths: ['*','*'],
                        body: [
                            [{text: 'INVOICE', style: 'invoice_header_text', colSpan: 2, alignment: 'center'}, {}],
                            [
                                {text: `No : ${data.code}`},
                                {text: `Tgl Inv : ${moment(data.rent_date).format("DD/MM/Y")}`, alignment: 'right'}
                            ],
                        ],
                    },
                    layout : {
                        hLineWidth: function(i, node) {
                            return i <= 1 ? 1 : 0
                        },
                        vLineWidth: function(i, node) {
                            return 0
                        },
                    }
                },
                {
                    style : "invoice_info",
                    table: {
                        widths: [70,1,170,80,1,'*'],
                        body: [
                            [
                                {text: 'Client'}, Object.assign({}, colon),
                                {text: data.member_name},
                                {text: 'Telp/Fax'}, Object.assign({}, colon),
                                {text: data.member.phone_number}],
                            [
                                {text: 'Nama Program'}, Object.assign({}, colon),
                                {text: data.program_name || '-'},
                                {text: 'Email'}, {text: ':'},
                                {text: data.member.email}
                            ],
                            [
                                {text: 'Alamat', rowSpan: 3},
                                {text: ':', rowSpan: 3},
                                {text: `${data.member.address}`, rowSpan: 3},
                                {text: 'Lokasi Shooting'}, Object.assign({}, colon),
                                {text: data.location || 'DKI Jakarta' }
                            ],
                            [
                                '','','',{text: 'Periode Tgl Sewa'}, Object.assign({}, colon),
                                {text: `${start_date.format("DD MMM Y")} (${diffInDay} Hari)`}
                            ],
                            [
                                '','','',{text: 'Tgl Pengembalian'}, Object.assign({}, colon),
                                {text: `${end_date.format("DD MMM Y")}`}
                            ],
                            [
                                {text: 'HP'}, Object.assign({}, colon),
                                {text: `${data.member.phone_number} ${data.member.phone_number2 === "" ? "" : (" / " + data.member.phone_number2)}`},
                                {text: data.due_date ? 'Tgl Jatuh Tempo' : ''}, Object.assign({}, data.due_date ? colon : { text: '' }),
                                {text: data.due_date ? `${moment(data.due_date).format("DD MMM Y")}` : ''}
                            ],
                        ]
                    },
                    layout : {
                        hLineWidth: function(i, node) {
                            return 0;
                        },
                        vLineWidth: function(i, node) {
                            return 0
                        },
                    }
                },
                {
                    style : "detail_header",
                    marginTop: 20,
                    table: {
                        widths: [18, 80, '*', 80, 25, 25, 80],
                        body: [
                            ['NO', 'TANGGAL', 'DESCRIPTION', 'PRICE', 'QTY', 'DAY', { text: 'SUBTOTAL', alignment: 'right' }],
                        ]
                    },
                    layout : {
                        hLineWidth: function(i, node) {
                            return 1
                        },
                        vLineWidth: function(i, node) {
                            return 0
                        },
                    }
                },
                {
                    style : "detail_detail",
                    table: {
                        widths: [18, 80, '*', 80, 25, 25, 80],
                        body: details_transaction
                    },
                    layout : {
                        hLineWidth: function(i, node) {
                            return 0
                        },
                        vLineWidth: function(i, node) {
                            return 0
                        },
                    }
                },
                {
                    style : "detail_detail",
                    table: {
                        widths: [18, 80, '*', 80, 25, 25, 80],
                        body: footer_transactions
                    },
                    layout : {
                        hLineWidth: function(i, node) {
                            return 1
                        },
                        vLineWidth: function(i, node) {
                            return 0
                        },
                    }
                },
                {
                    style : "footer_text",
                    marginTop : 5,
                    columns: [
                        {
                            width: 250,
                            stack: [
                                {
                                    text: '\nDetail Pembayaran',
                                    style: {
                                        bold: true,
                                        fontSize: 11
                                    }
                                },
                                ...payment_accounts
                            ],
                        },
                        {
                            width: '*',
                            text: ''
                        },
                        {
                            stack: [
                                data.cabang.formal_name,
                                {
                                    image: data.cabang.stamp,
                                    width: data.cabang.stamp_width,
                                    height: 80,
                                    margin: [0, 10]
                                },
                                {
                                    text: data.cabang.id === 3 ? '(Evo Hekta)' : '(Aditya Permana)',
                                }
                            ],
                            width: 200,
                            marginTop: 10,
                            style: {
                                alignment : "right"
                            },
                        }
                    ]
                },
                {
                    marginTop: 10,
                    style : "footer_text",
                    stack: [
                        {
                            text: 'Catatan:',
                            style: {
                                bold: true,
                                lineHeight: 1.2
                            },
                        },
                        {
                            text: data.notes
                        }
                    ],
                }
            ],
            styles: {
                invoice_header_text : {
                    fontSize : 12
                },
                invoice_header : {
                    marginTop : 10,
                    bold : true
                },
                invoice_info : {
                    fontSize : 9
                },
                detail_header : {
                    fontSize :  10,
                    bold: true
                },
                detail_detail : {
                    fontSize :  8,
                },
                footer_text: {
                    fontSize: 10
                }
            },
            pageSize: 'FOLIO',
            defaultStyle: {
                columnGap: 20,
            }
        }

        return pdfMake.createPdf(definition);
    }

    static async suratJalan(data){
        let start_date = moment(data.start_date, "Y-MM-DD");
        let end_date = moment(data.end_date, "Y-MM-DD");
        let diffInDay = end_date.diff(start_date, "days") + 1;

        const headerStyle = {
            fontSize :  14,
            alignment : 'center'
        };
        let details_transaction = [
            [{ text: 'NO', style: headerStyle }, { text: 'DESCRIPTION', style: headerStyle }, { text: 'QTY', style: headerStyle }, { text: 'SERIAL', style: headerStyle }, { text: 'KETERANGAN', style: headerStyle }],
        ];
        let detail_no = 1;

        data.products.forEach(dt => {
            let serialsText = dt.serials.map(serial => {
                return serial;
            });
            serialsText = serialsText.join(", ");

            let dtr = [
                {text:(detail_no++).toString(), style:{ bold: true }},
                {text:dt.name, style:{ bold: true }},
                {text:dt.serials.length.toString(), style:{ bold: true }},
                {text: serialsText},
                ''
            ];
            details_transaction.push(dtr);
            if(dt.includes.length !== 0){
                dt.includes.forEach((include, idx) => {
                    details_transaction.push(['',{text: include, style:{ italics : true }},'','','']);
                });
            }
        });

        let colon = {
            border : [false, true, false, true],
            text : ':'
        }

        const SIGNS = {}

        await Promise.all(
            data.transaction_signs.map(ts => new Promise(async resolve => {
                try {
                    const base64 = await Utils.getBase64FromUrl(ts.sign)
                    SIGNS[ts.category] = {
                        image: base64,
                        created_at: ts.created_at ? moment(ts.created_at).format("DD-MM-Y H:m:s") :  '-'
                    }
                } catch (e) {}
                resolve();
            }))
        )

        const getSign = (signId) => {
            let results = [];

            if (SIGNS[signId]) {
                results = [
                    ...results,
                    ...[
                        {
                            image: SIGNS[signId].image,
                            width: 130,
                            height: 40,
                        },
                        {
                            text : `Ditandatangani pada ${SIGNS[signId].created_at}`,
                            style : {
                                alignment : "center",
                                bold : true,
                                fontSize : 5
                            }
                        }
                    ]
                ]
            } else {
                results = [
                    ...results,
                    { text: '\n\n\n\n' },
                ]
            }

            return results
        }

        let definition = {
            content: [
                {
                    style : "surjal_title",
                    text : "SURAT JALAN"
                },
                {
                    style : "invoice_info",
                    table: {
                        widths: [50,1,300,120,1,'*'],
                        body: [
                            [{text: 'Client'}, Object.assign({}, colon), {text: data.member_name},
                                {text: 'Status Pengiriman'}, Object.assign({}, colon), {text: (data.status_pengiriman || 'delivery').capitalize()}],
                            [{text: 'Alamat', rowSpan: 4}, {text: ':', rowSpan: 4},
                                {text: data.member.address, rowSpan: 4}, {text: 'Crew antar'}, {text: ':'},
                                {text: `\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tCrew\t\tjemput\t\t: `}],
                            [{text: ''},'','',{text: `Lokasi\n\n`}, Object.assign({}, colon), {text: ''}],
                            ['','','',{text: 'Periode'}, Object.assign({}, colon), {text: `${start_date.format("DD MMM Y")} (${diffInDay} Hari)`}],
                            ['','','',{text: 'Pengembalian'}, Object.assign({}, colon), {text: `${end_date.format("DD MMM Y")}`}],
                            [{text: 'HP'}, Object.assign({}, colon), {text: data.member.phone_number, colSpan: 4}],
                        ]
                    },
                    layout : {
                        hLineWidth: function(i, node) {
                            return (i === 0) ? 1 : 0;
                        },
                        vLineWidth: function(i, node) {
                            return 0;
                        },
                    }
                },
                {
                    style : "detail_detail",
                    table: {
                        widths: [22, 330, 30, 200, '*'],
                        headerRows: 1,
                        body: details_transaction
                    },
                    layout : {
                        hLineWidth: function(i, node) {
                            return i === node.table.body.length || i === node.table.headerRows || i === 0 ? 1 : 0;
                        },
                        vLineWidth: function(i, node) {
                            return (i === 0 || i === node.table.body[0].length) ? 0 : 1;
                        },
                        paddingBottom: function(i, node) {
                            let lastTop = 0;
                            let lastPage = 1;
                            node.table.body.forEach(x => {
                                x.forEach(y => {
                                    if (y.positions && y.positions.length > 0) {
                                        lastTop = y.positions[y.positions.length - 1].top;
                                        lastPage = y.positions[y.positions.length - 1].pageNumber;
                                    }
                                })
                            })
                            lastTop += 30;

                            let final = 1056 - 100 - 280 - lastTop;
                            if (final < 0) final = 0;
                            return (i === node.table.body.length - 1 && lastTop < 700 && lastPage === 1) ? final : 1;
                        }
                    }
                },
                {
                    style : "keterangan",
                    marginTop: 10,
                    unbreakable: true,
                    table: {
                        widths: ['*'],
                        heights : [90],
                        body: [
                            [`Keterangan\n\n${data.notes || "-\n\n"}`],
                        ]
                    },
                    layout : {
                        hLineWidth: function(i, node) {
                            return 1;
                        },
                        vLineWidth: function(i, node) {
                            return (i === 0 || i === node.table.body[0].length) ? 0 : 1;
                        },
                    }
                },
                {
                    unbreakable: true,
                    stack: [
                        {
                            marginTop: 10,
                            text : "PENYERAHAN",
                            style : {
                                alignment : "center",
                                bold : true,
                                fontSize : 14
                            },
                        },
                        {
                            style : "detail_footer",
                            table: {
                                widths: ['*', '*', '*'],
                                heights : [50],
                                body: [
                                    [
                                        [{text: `Admin (${data.admin ? data.admin.name : 'Anonim'})`}, ...getSign(1)],
                                        [{text: 'Gudang/Driver'}, ...getSign(2)],
                                        [{text: 'Yang Menerima (Customer)'}, ...getSign(3)],
                                    ],
                                ]
                            },
                            layout : {
                                hLineWidth: function(i, node) {
                                    return 1;
                                },
                                vLineWidth: function(i, node) {
                                    return (i === 0 || i === node.table.body[0].length) ? 0 : 1;
                                },
                            }
                        },
                        {
                            marginTop: 10,
                            text : "PENGEMBALIAN",
                            style : {
                                alignment : "center",
                                bold : true,
                                fontSize : 14
                            }
                        },
                        {
                            style : "detail_footer",
                            table: {
                                widths: ['*', '*', '*'],
                                heights : [50],
                                body: [
                                    [
                                        [{text: 'Yang Menerima'}, ...getSign(4)],
                                        [{text: 'Gudang/Driver'}, ...getSign(5)],
                                        [{text: 'Yang Menyerahkan (Customer)'}, ...getSign(6)],
                                    ]
                                ]
                            },
                            layout : {
                                hLineWidth: function(i, node) {
                                    return 1;
                                },
                                vLineWidth: function(i, node) {
                                    return (i === 0 || i === node.table.body[0].length) ? 0 : 1;
                                },
                            }
                        },
                    ],
                },
            ],
            styles: {
                surjal_title : {
                    fontSize : 16,
                    marginBottom : 10,
                    bold : true,
                    alignment : "center"
                },
                invoice_header : {
                    marginTop : 10,
                    bold : true
                },
                invoice_info : {
                    fontSize : 14
                },
                detail_footer : {
                    fontSize :  14,
                    alignment : 'center'
                },
                detail_detail : {
                    fontSize :  13,
                },
                keterangan : {
                    fontSize : 14
                }
            },
            pageSize: {
                width: 912,
                height: 1056
            },
            pageMargins: [60, 50, 60, 50],
            defaultStyle: {
                columnGap: 20,
            },
        }
        return pdfMake.createPdf(definition)
    }

    static beritaAcara(data){
        let definition = {
            footer: {
                columns: [
                    '',
                    {   text: [
                            '____________________________________________________________\n',
                            'www.bsmentertainment.com',
                        ],
                        color: '#6c757d',
                        alignment: 'right',
                        fontSize: 9,
                        marginRight: 40
                    }
                ]
            },
            content: [
                {
                    text: 'BERITA ACARA SELESAI PEKERJAAN',
                    bold: true,
                    alignment: 'center',
                    style: {
                        fontSize: 14,
                    },
                    marginBottom: 20
                },
                {
                    text: [
                        'Pada hari ini tanggal ',
                        {
                            text: moment(data.start_date).format('DD MMMM YYYY'),
                            bold: true
                        },
                        ' telah selesai dilakukan proses pemasangan dan pengerjaan instalasi di lapangan/lokasi sesuai dengan PO No. ',
                        {
                            text: data.code,
                            bold: true
                        },
                    ],
                    alignment: 'justify',
                    style: {
                        fontSize: 10,
                    },
                    marginBottom: 10
                },
                {
                    text: `Semua peralatan sudah terpasang dengan baik dan lengkap sesuai pesanan. Serta TIDAK ADA pekerjaan tambah.`,
                    alignment: 'justify',
                    style: {
                        fontSize: 10,
                    },
                    marginBottom: 10
                },
                {
                    text: `Demikianlah Berita Acara ini dibuat dan di tandatangani bersama oleh masing-masing pihak.`,
                    alignment: 'justify',
                    style: {
                        fontSize: 10,
                    },
                    marginBottom: 10
                },
                {
                    text: 'Yang menandatangani,',
                    style: {
                        fontSize: 10,
                    },
                    bold: true,
                    marginBottom: 15
                },
                {
                    columns: [
                        {
                            stack: [
                                {
                                    text: 'Vendor,',
                                    alignment: 'center',
                                    bold: true,
                                    style: {
                                        fontSize: 10,
                                    },
                                },
                                {
                                    text: `(${data.cabang.formal_name})`,
                                    alignment: 'center',
                                    marginTop: 70,
                                    style: {
                                        fontSize: 10,
                                    },
                                }
                            ],
                        },
                        {
                            stack: [
                                {
                                    text: 'Customer,',
                                    alignment: 'center',
                                    bold: true,
                                    style: {
                                        fontSize: 10,
                                    },
                                },
                                {
                                    text: `(${data.member_name})`,
                                    alignment: 'center',
                                    marginTop: 70,
                                    style: {
                                        fontSize: 10,
                                    },
                                }
                            ],
                        }
                    ]
                },
                {
                    text: 'Catatan:',
                    style: {
                        fontSize: 10,
                        decoration: 'underline'
                    },
                    bold: true,
                    marginTop: 15,
                    marginBottom: 10
                },
                {
                    table: {
                        widths: ['*'],
                        body: [
                            [
                                {text: '\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n'},
                            ],
                        ]
                    }
                },
                {
                    ul: [
                        'Catatan diisi oleh Customer/Klien apabila ada complaint.',
                        'Vendor wajib mengisi lampiran tambahan jika ada permintaan tambahan di lapangan.'
                    ],
                    style: {
                        fontSize: 8,
                    },
                    marginTop: 10
                }
            ],
            pageSize: 'A4',
            pageMargins: [95, 95, 95, 40],
            defaultStyle: {
                columnGap: 20,
            }
        }

        return pdfMake.createPdf(definition);
    }

    static kwitansi(data){
        let definition = {
            footer: {
                columns: [
                    '',
                    {   text: [
                            '____________________________________________________________\n',
                            'www.bsmentertainment.com',
                        ],
                        color: '#6c757d',
                        alignment: 'right',
                        fontSize: 9,
                        marginRight: 40
                    }
                ]
            },
            content: [
                {
                    columns: [
                        {
                            image: data.cabang.logo,
                            width: 130,
                            height: 40,
                            marginTop: 10
                        },
                        {
                            alignment: 'right',
                            stack: [
                                {
                                    text: 'KWINTANSI',
                                    marginBottom: 10,
                                    marginTop: 10,
                                    bold: true,
                                    style: {
                                        fontSize: 16
                                    }
                                },
                                {
                                    text: `Invoice No. ${data.code}`
                                }
                            ],
                        }
                    ]
                },
                {
                    marginTop: 60,
                    table: {
                        widths: [180, '*'],
                        body: [
                            [
                                {
                                    text: 'Telah Menerima Dari',
                                    marginTop: 12,
                                    marginBottom: 12,
                                    bold: true
                                },
                                {
                                    text: data.member_name,
                                    marginTop: 12,
                                    marginBottom: 12,
                                }
                            ],
                            [
                                {
                                    text: 'Banyaknya Uang',
                                    marginTop: 12,
                                    marginBottom: 12,
                                    bold: true
                                },
                                {
                                    text: Utils.terbilang(data.total_price),
                                    marginTop: 12,
                                    marginBottom: 12,
                                }
                            ],
                            [
                                {
                                    text: 'Untuk Pembayaran',
                                    marginTop: 12,
                                    marginBottom: 12,
                                    bold: true
                                },
                                {
                                    text: [
                                        'Sewa tanggal ',
                                        {
                                            text: moment(data.start_date).format('DD MMMM YYYY'),
                                            bold: true
                                        },
                                        ' - ',
                                        {
                                            text: moment(data.start_date).format('DD MMMM YYYY'),
                                            bold: true
                                        },
                                        ' dengan nomor invoice ',
                                        {
                                            text: data.code,
                                            bold: true
                                        }
                                    ],
                                    marginTop: 12,
                                    marginBottom: 12,
                                }
                            ],
                        ],
                    },
                    layout: {
                        vLineWidth: function (i, node) {
                            return 0;
                        },
                    }
                },
                {
                    marginTop: 40,
                    columns: [
                        {
                            columns: [
                                {
                                    width: 80,
                                    text: 'Jumlah Rp',
                                    bold: true,
                                    marginTop: 3,
                                    style: {
                                        fontSize: 16
                                    }
                                },
                                {
                                    bold: true,
                                    style: {
                                        fontSize: 16
                                    },
                                    table: {
                                        widths: ['auto'],
                                        body: [
                                            [
                                                {
                                                    text: `${Utils.rupiah(data.total_price, '')},00`
                                                }
                                            ]
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            text: moment(data.rent_date).format('DD MMMM YYYY'),
                            alignment: 'center',
                            bold: true
                        }
                    ]
                }
            ],
            pageSize: {
                height: 500,
                width: 800
            },
            defaultStyle: {
                columnGap: 20,
            }
        }

        return pdfMake.createPdf(definition);
    }
}

export default Report;
